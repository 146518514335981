* {
  box-sizing: border-box;
  padding: 0;
  appearance: none;
  scroll-behavior: smooth;
}

*:focus {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

.ant-drawer .ant-drawer-content-wrapper .ant-drawer-header {
  background-color: #00003d;
  border: unset;
}

.ant-drawer .ant-drawer-content-wrapper .ant-drawer-body {
  padding: 0;
  overflow-x: hidden;
  background-color: #00003d;
  display: flex;
  flex-direction: column;
}

.ant-menu-dark.ant-menu-horizontal{
  width: 100%;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.ant-menu-light.ant-menu-horizontal{
  width: 100%;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  border: none;

}

.ant-drawer-close {
  color: #d0d1d6 !important;
}

.slick-list .slick-track{
  width: 100%;
}

.hScreen{
  margin-top: 50px;
  min-height: 100vh;
}